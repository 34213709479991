import React from "react";
import { Link } from "gatsby";
import { SEO } from "components";

/* Zine Page
============================================================================= */

const ZinePage = () => {
  // Config
  return (
    <>
      <SEO title="Contagious Zine" />
      <div />
    </>
  );

  // Render
};

/* Export
============================================================================= */

export default ZinePage;
